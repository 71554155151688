
import React, { useEffect, useState } from "react";
import {
  SortOrder,
  IColumnState,
} from "../../../../types/types";
import isEmpty from "lodash/isEmpty";
import styles from "./BusinessInfoTable.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import downloadLight from "../../../../assets/images/LightTheme/download_light.svg";
import downloadDark from "../../../../assets/images/DarkTheme/dowload_dark.svg";
import deleteLight from "../../../../assets/images/LightTheme/delete_light.svg";
import deleteDark from "../../../../assets/images/DarkTheme/delete_dark.svg";
import arrow_up_light from "../../../../assets/images/LightTheme/arrow_up_light.svg";
import arrow_up_dark from "../../../../assets/images/DarkTheme/arrow_up_dark.svg";
import arrow_down_light from "../../../../assets/images/LightTheme/arrow_down_light.svg";
import arrow_down_dark from "../../../../assets/images/DarkTheme/arrow_down_dark.svg";
import nosort_light from "../../../../assets/images/LightTheme/nosort_light.svg";
import nosort_dark from "../../../../assets/images/DarkTheme/nosort_dark.svg";
import downArrowPercent from "../../../../assets/images/downArrowPercent.svg";
import upArrowPercent from "../../../../assets/images/upArrowPercent.svg";
import downArrowPercentDark from "../../../../assets/images/downArrowPercentDark.svg";
import upArrowPercentDark from "../../../../assets/images/upArrowPercentDark.svg";
import { sortAccountTableData } from "../../../../utils/common";
import { formatDate } from "../../../../utils/dateFormatter";
import { countryCodeMappings } from '../../../../constants/dataConstants';
import { useTranslation } from "react-i18next";
import { getInitials } from "../../../../utils/common";
import { getGradientBackground } from "../../../../utils/getGradientBackground";
import { getTrackingAwardIconFromCode, getAwardNameFromCode, getTrackingAwardFromCode, getTrackingAwardColorFromCode, getCountryNameFromCode } from "../../../../utils/awardIconMapping";
import { useNavigate } from "react-router-dom";
import { setInitialUserDetails } from "../../../../redux/slices/userDetailsSlice";
import { useDispatch } from "react-redux";
import { CurrencyFormatter } from '../../../../utils/currencyFormatter';
import { saveStateToSessionStorage } from "../../../../utils/sessionStorageUtility";
import { NumberFormatter } from '../../../../utils/NumberFormatter';

interface TableProps<T> {
  tableData: T[]; // Define columns as an array of keys of T
  isLoading: boolean,
  columnState: IColumnState;
  setColumnState: (arg: IColumnState) => void,
  rowLimit?: number,
  rowStepperLimit?: number,
  tableColumnData: string[],
  tableColumnNameMapper: string[],
  isSortable: boolean
}

// Default column name which is a key of ITableColumnDetails

const BusinessInfoTabel = <T,>({ tableData, isLoading, columnState, setColumnState, tableColumnData, tableColumnNameMapper, isSortable, rowLimit, rowStepperLimit }: TableProps<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableDataList, setTableDataList] = useState<any[]>(tableData);

  useEffect(() => {
    setTableDataList(tableData);
  }, [tableData]);

  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);

  const [isViewmore, setViewMore] = useState<boolean>(false);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const selectedSortackgroundColor = currentTheme === "light" ? "#CACACA" : "#494949";
  const downloadIcon = currentTheme === "light" ? downloadLight : downloadDark;
  const deleteIcon = currentTheme === "light" ? deleteLight : deleteDark;
  const upArrowIcon = currentTheme === "light" ? upArrowPercent : upArrowPercentDark;
  const downArrowIcon = currentTheme === "light" ? downArrowPercent : downArrowPercentDark;

  const handleSort = (column: string) => {
    const tableHeaderNameIndex = tableColumnData.indexOf(column);
    const name = tableColumnNameMapper[tableHeaderNameIndex] as keyof IColumnState;
    const currentKeySortOrder = columnState[name];
    Object.keys(columnState).forEach((keyName: string) => columnState[keyName as keyof IColumnState] = 'nosort')
    let newOrder: SortOrder;

    switch (currentKeySortOrder) {
      case 'nosort':
        newOrder = 'asc';
        break;
      case 'asc':
        newOrder = 'desc';
        break;
      case 'desc':
        newOrder = 'nosort';
        break;
      default:
        newOrder = 'nosort';
    }

    const newSortState = {
      ...columnState,
      [name]: newOrder
    };

    setColumnState(newSortState);
    const currentTableSortedData = getSortedData();
    setTableDataList(currentTableSortedData);
  };

  const getColumnStateNameKey = (columnName: string) => {
    const tableHeaderNameIndex = tableColumnData.indexOf(columnName);
    const name = tableColumnNameMapper[tableHeaderNameIndex] as keyof IColumnState;
    const sortname: SortOrder = columnState[name];
    return sortname;
  }

  const getSortedData = () => {
    let sortedData = [...tableData];

    for (const [column, order] of Object.entries(columnState)) {
      if (order !== 'nosort') {
        sortedData = sortAccountTableData(sortedData, column, order);
        break; // Only sort by one column at a time
      }
    }

    return sortedData;
  };

  const addTableSortIcon = (column: string) => {

    let headerCellIcon = '';
    const tableHeaderNameIndex = tableColumnData.indexOf(column);
    const name = tableColumnNameMapper[tableHeaderNameIndex] as keyof IColumnState;
    const isValidForTheCell = columnState.hasOwnProperty(name) && columnState[name];
    if (currentTheme === "light") {
      headerCellIcon = isValidForTheCell == "asc" ? arrow_up_light : isValidForTheCell === "desc" ? arrow_down_light : nosort_light;
    } else {
      headerCellIcon = isValidForTheCell == "asc" ? arrow_up_dark : isValidForTheCell === "desc" ? arrow_down_dark : nosort_dark;
    }
    return headerCellIcon;
  }

  const handleAboInfoClick = (data: any) => {
    if(data && data?.currentAward && data?.currentAward?.awardCode && (data?.currentAward?.awardCode == '305' || data?.currentAward?.awardName?.toLowerCase() === 'silver sponsor')) return;
    if (data.aboNumber && data.affiliateCode) {
      dispatch(setInitialUserDetails({
        userDetails: {
          ...userDetails,
          affiliateCode: data.affiliateCode, // "010"
          aboNumber: data.aboNumber, // 670 
        }
      }))
      saveStateToSessionStorage({
        affiliateCode: data.affiliateCode,
        aboNumber: data.aboNumber,
      });
      setTimeout(() => {
        navigate(`/profile`);
      }, 1000);
    }
  };

  const buildTableCell = (colType: string, data: any) => {
    const background = getGradientBackground({ tag: 'good' });
    switch (colType) {

      case "info": return (
        <td className={`data_cell ${styles.data_cell}`} onClick={() => handleAboInfoClick(data)} data-testid="Leg_IBO_info_testID">
          <div className={styles.infoCell}>
            <div
              className={styles.info_logo_outer}
              style={{ background: background.initials }}
            >
              <label className={styles.info_logo_inner}>
                {getInitials(data.name || "")}
              </label>
            </div>
            <div className={`table_info ${styles.infoRightSection}`}>
              <label className={`labelInfo ${styles.labelInfo}`}>
                {data?.name || ""}
              </label>
              <label className={styles.labelInfoBottom}>
                {data?.marketCountryCode && data?.marketCountryCode !== "" ? data?.aboNumber + " | " +  getCountryNameFromCode(data?.marketCountryCode, t) : data?.aboNumber}
              </label>
            </div>
          </div>
        </td>);
      case "trackerAward": return (
        <td className={`data_cell ${styles.data_cell} ${styles.emptyDataCell}`}>
          {data?.awardCode ? <span
            className={styles.trackerAward}
          >
            {getAwardNameFromCode(data?.awardCode, t)}
          </span> : "-"}
          {/* {data?.awardTagCode ?
            <div className={`award_icon ${styles.awardIcon}`} style={{ backgroundColor: currentTheme === "light" ? "#F4F4F4" : "#1C1C1E" }}>
              <span className={`award_icon_left ${styles.awardIconLeft}`}>
                {getTrackingAwardIconFromCode(data?.awardTagCode, currentTheme)}
              </span>
              <span className={`award_name ${styles.awardIconRight}`} style={{ color: getTrackingAwardColorFromCode(data?.awardTagCode, currentTheme) }}>
                {getTrackingAwardFromCode(data?.awardTagCode, t)}
              </span>
            </div> : "-"
          } */}
        </td>);

      case "revenue": return (
        <td className={`data_cell ${styles.data_cell}`}>
          <span
            className={styles.revenue}
          >
            {data.revenue !== null && data.revenue !== undefined ?
              data?.currency !== "" ?
                String(CurrencyFormatter(data.currency, Math.round(data.revenue))).replace(/\.00$/, '')
				: NumberFormatter(userAffiliateCode, data.revenue)
                // : data.revenue
              : '-'
            }
          </span>
          <div className={styles.revPercentOuter}
            style={{ backgroundColor: data?.percentage ? data.percentage > 0 ? (currentTheme === 'light' ? "#F0FAE5" : "#1C1C1E") : (currentTheme === 'light' ? "#FDF4F4" : "#1C1C1E") : '' }}
          >
            {data?.percentage ? <>
              <img
                src={data?.percentage > 0 ? upArrowIcon : downArrowIcon}
                alt="percentage Indicator"
                id="percentIndicator"
                className={styles.revenueIndicator}
              />
              <span
                className={styles.revenuepercent}
                style={{ color: data.percentage > 0 ? (currentTheme === 'light' ? "#107F47" : "#B3D67B") : (currentTheme === 'light' ? "#D91734" : "#F16A81") }}
              >
                {Math.abs(Math.round(data.percentage)) + "%"}
              </span>
            </> : '-'
            }
          </div>
        </td>);
      case "date": return <td className={`data_cell ${styles.data_cell}`}>{formatDate(data.updatedDate, countryCodeMappings[userAffiliateCode], 'dateTime')} </td>;
      case "docActions": return (<td className={`data_cell ${styles.data_cell}`}>
        <div
          className={`download_icon ${styles.download_icon}`}
        >
          <img src={downloadIcon} alt="download" />
        </div>
        <div
          className={styles.delete_icon}
        >
          <img src={deleteIcon} alt="delete" />
        </div>
      </td>);

      default: return <div>Column type not matched</div>;

    }
  }

  return isLoading ? (
    <div className={styles.empty_state_document}>
      <div className={`table_no_data ${styles.empty_state_msg}`}>
        {t("loading")}
      </div>
    </div>
  ) : (
    isEmpty(tableDataList) ?
      <div className={styles.empty_state_document}>
        <div className={`table_no_data ${styles.empty_state_msg}`}>
          {t("no_legs_to_display")}
        </div>
      </div>
      :
      <section
        className={`profile_table table_container ${styles.table_container}`}
        data-testid="table_section_testID"
      >
        <div className={styles.table_wrapper}>
          <table>
            <thead className={styles.tbl_header_wrapper}>
              <tr className={`tbl_header ${styles.tbl_header}`}>
                {tableColumnData?.map((columnName: string, index: any) => (
                  <th
                    key={index}
                    className={`header_cell ${styles.header_cell}`}
                    data-testid="table_column_header_testID"
                    // style={{
                    //   backgroundColor: getColumnStateNameKey(columnName) !== "nosort" ? selectedSortackgroundColor : ""
                    // }}
                  >
                    <span>{t(columnName)}</span>
                    {isSortable && <img
                      src={addTableSortIcon(columnName)}
                      alt="sort"
                      style={{ display: getColumnStateNameKey(columnName) !== "nosort" ? "flex" : "" }}
                      onClick={() => handleSort(columnName)}
                      id={`${columnName}`}
                    />}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>

              {tableDataList?.slice(0, rowLimit ? isViewmore ? 15 : rowLimit : Number.MAX_SAFE_INTEGER)
                ?.map(
                  (rowData: any, index: number) => (
                    <tr
                      key={`${index}`}
                      className={`tbl_data_row ${styles.tbl_data_row}`}
                      data-testid="LegAnalysis_table_row_testID"
                      style={{ width: "auto" }}
                    >
                      {buildTableCell('info', {
                        name: rowData.name,
                        aboNumber: rowData.aboNumber,
                        localName: rowData.localName,
                        ...(rowData?.marketCountryCode) && { marketCountryCode: rowData.marketCountryCode },
                        ...(rowData?.currentAward) && { currentAward: rowData.currentAward },
                        ...(rowData?.affiliateCode) && { affiliateCode: rowData.affiliateCode }
                      })}
                      {buildTableCell('revenue', {
                        ...(rowData?.monthlyRevenue?.localRevenue !== 0) && { revenue: rowData?.monthlyRevenue?.localRevenue },
                        ...(rowData?.monthlyRevenue?.localRevenuePerformancePercentage) && { percentage: rowData?.monthlyRevenue?.localRevenuePerformancePercentage },
                        ...(rowData?.monthlyRevenue?.localRevenueCurrency) && { currency: rowData?.monthlyRevenue?.localRevenueCurrency }
                      })}
                      {buildTableCell('revenue', {
                        ...(rowData?.monthlyContributor?.contributor !== null) && { revenue: rowData?.monthlyContributor?.contributor },
                        ...(rowData?.monthlyContributor?.contributorPerformancePercentage) && { percentage: rowData?.monthlyContributor?.contributorPerformancePercentage },
                        currency: ""
                      })}
                      {buildTableCell('revenue', {
                        ...(rowData?.annualRevenue?.localRevenue !== null) && { revenue: rowData?.annualRevenue?.localRevenue },
                        ...(rowData?.annualRevenue?.localRevenuePerformancePercentage) && { percentage: rowData?.annualRevenue?.localRevenuePerformancePercentage },
                        currency: rowData?.annualRevenue?.localRevenueCurrency
                      })}
                      {buildTableCell('revenue', {
                        ...(rowData?.annualContributor?.contributor !==  null ) && { revenue: Math.round(rowData?.annualContributor?.contributor)},
                        ...(rowData?.annualContributor?.contributorPerformancePercentage) && { percentage: rowData?.annualContributor?.contributorPerformancePercentage },
                        currency: ''
                      })}
                    </tr>
                  )
                )}
              {rowLimit && tableDataList?.length > rowLimit && (
                <div className={styles.viewmore_Section}>
                  <button
                    className={`view_more_text ${styles.viewmore_label}`}
                    onClick={() => setViewMore(!isViewmore)}
                    data-testid="view_more_testId"
                  >
                    {isViewmore ? t("view_less") : t("view_more")}
                  </button>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </section>
  );
};

export default BusinessInfoTabel;
